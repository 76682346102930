import React, { useState, useEffect } from "react"

import LayoutOB from "../../components/layoutob.js"
import Seo from "../../components/seo"
import { useLocation } from "@reach/router"

import FormSignup from "../../components/Form/signup/FormSignup"
import {frCH } from "../../lang/fr-CH"

export default function CheckoutPage() {
  const location = useLocation()

  var searchParams = new URLSearchParams(location.search)
  var countryParam = searchParams.get("country")
  const [country, setCountry] = useState(countryParam)
  let txt = frCH.Checkout

  const handleUpdateCountry = input => {
    setCountry(input)
  }

  useEffect(() => {
    txt = frCH.Checkout
  }, [country])

  return (
    <LayoutOB location="CH" language="fr-CH">
      <Seo title="DoryGo - Checkout" description="Checkout" lang="fr-CH" />
      <FormSignup
        country="CH"
        txt={txt}
        handleUpdateCountry={handleUpdateCountry}
        language="fr-CH"
      />
    </LayoutOB>
  )
}
